import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import googleBigqueryImg from "../../../components/database/images/google-bigquery.svg";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/google_bigquery_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/google-bigquery/";

const SQLServer = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant REST APIs on Google BigQuery from Hasura"
      description="Hasura connects to existing Google BigQuery database and gives instant REST CRUD APIs with Authorization."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST API for Google BigQuery"
      desc="Hasura supports Google BigQuery and gives instant REST APIs"
      btnLink="https://cloud.hasura.io/signup?pg=google-bigquery&plcmt=body&cta=try-it-out-in-30-seconds&tech=default"
      btnText="Try it Out in 30s"
      imgSrc={googleBigqueryImg}
      linkUrl="https://hasura.io/docs/latest/graphql/core/databases/bigquery/getting-started.html"
      linkText="Check out the docs here"
    />
  </Layout>
);

export default SQLServer;
